import * as React from 'react';
import * as Constants from 'src/constants';
import InnerPortal from 'src/components/Portal';
import { ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native';

interface PropsIface {
  onClose: () => void;
  visible: boolean;
  size: 'extra-thin' | 'thin' | 'normal';
}

export const Modal: React.FC<PropsIface> = (props) => {
  const onBackgroundPress = React.useCallback(() => {
    props.onClose();
  }, [props.onClose]);

  React.useEffect(() => {
    if (props.visible) {
      document.body.classList.add('noscroll');
    } else {
      document.body.classList.remove('noscroll');
    }
  }, [props.visible]);

  const widthStyle = props.size == 'extra-thin' ? styles.extraThin : props.size == 'thin' ? styles.thin : styles.normal;
  return props.visible ? (
    <>
      <InnerPortal id='modal-card'>
        <View style={styles.background}>
          <TouchableOpacity onPress={onBackgroundPress} style={styles.backdrop} />
          <View style={[styles.inner, widthStyle]}>{props.children}</View>
        </View>
      </InnerPortal>
    </>
  ) : null;
};

const styles = StyleSheet.create({
  background: {
    // @ts-ignore: (RNW CSS)
    position: 'fixed',
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 999,
  },
  backdrop: {
    // @ts-ignore: (RNW CSS)
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  inner: {
    // @ts-ignore: (RNW CSS)
    flex: 'unset',
    // @ts-ignore: (RNW CSS)
    flexGrow: 'unset',
    // @ts-ignore: (RNW CSS)
    boxShadow: '0px 16px 70px rgba(0,0,0,.25)',
    // @ts-ignore: (RNW CSS)
    position: 'relative',
    borderRadius: Constants.Grid.dp(12),
    top: '50%',
    left: '50%',
    backgroundColor: Constants.NewColor.White,
    transform: [
      {
        // @ts-ignore: (RNW CSS)
        translateX: '-50%',
      },
      {
        // @ts-ignore: (RNW CSS)
        translateY: '-50%',
      },
    ],
    maxHeight: Constants.Grid.dp(640),
    overflow: 'hidden',
  },
  extraThin: {
    width: Constants.Grid.dpFromPx(240),
  },
  thin: {
    width: Constants.Grid.dpFromPx(480),
  },
  normal: {
    width: Constants.Grid.dpFromPx(640),
  },
});
