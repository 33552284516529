import React from 'react';
import { createPortal } from 'react-dom';
import usePortal from './usePortal';

interface PropsIface {
  id: string;
}

const Portal: React.FC<PropsIface> = (props) => {
  const target = usePortal(props.id);
  return target != null ? createPortal(props.children, target) : null;
};

export default Portal;
