import * as React from 'react';
import * as Constants from 'src/constants';
import { StyleSheet, TouchableOpacity, TouchableOpacityProps, View, ViewProps } from 'react-native';

/**
 * For the horizontal gutters on the edge of the screen.
 */
export const EdgeGutter: React.FC<TouchableOpacityProps> = (props) => {
  if (props.onPress != null) {
    return (
      <TouchableOpacity activeOpacity={0.7} {...props} style={[styles.root, props.style]}>
        {props.children}
      </TouchableOpacity>
    );
  } else {
    return <View style={[styles.root, props.style]}>{props.children}</View>;
  }
};

const styles = StyleSheet.create({
  root: {
    paddingHorizontal: Constants.Grid.dp(12),
  },
});
