import * as React from 'react';
import * as Constants from 'src/constants';
import { EdgeGutter } from '../EdgeGutter';
import Text from 'src/components/Text';
import { Switch, StyleSheet, View } from 'react-native';

const TRACK_COLOR = { false: Constants.Color.DarkShade, true: Constants.Color.Affirmative };

interface PropsIface {
  defaultValue?: boolean;
  disabled?: boolean;
  trackColor?: Constants.Color;
  titleNode: React.ReactNode;
  descriptionNode?: React.ReactNode;
  onChange?: (value: boolean) => void;
  onPress?: () => void;
}

export const SettingsSwitchRow: React.FC<PropsIface> = (props) => {
  const [enabled, setEnabled] = React.useState<boolean>(Boolean(props.defaultValue) ?? false);

  const toggleSwitch = React.useCallback(() => {
    setEnabled(!enabled);
    if (props.onChange != null) {
      props.onChange(!enabled);
    }
  }, [props.onChange, enabled]);

  const trackColor: typeof TRACK_COLOR = React.useMemo(() => {
    const trackColorOverride = props.trackColor;
    if (trackColorOverride != null) {
      return { false: Constants.Color.DarkShade, true: trackColorOverride };
    } else {
      return TRACK_COLOR;
    }
  }, [props.trackColor]);

  return (
    <EdgeGutter style={styles.root} onPress={props.onPress}>
      <View style={props.descriptionNode == null ? styles.titleAlone : styles.titleWithDescription}>
        <Text style={[Constants.TextStyle.T16B]}>{props.titleNode}</Text>
        {props.descriptionNode != null ? (
          <Text style={[styles.description, Constants.TextStyle.T12R, Constants.TextStyle.CSecondary]}>
            {props.descriptionNode}
          </Text>
        ) : null}
      </View>
      <Switch
        disabled={props.disabled}
        trackColor={trackColor}
        thumbColor={Constants.Color.Inverse}
        onValueChange={toggleSwitch}
        value={enabled}
        style={styles.switch}
      />
    </EdgeGutter>
  );
};

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    minHeight: Constants.Grid.dp(50),
  },
  icon: {
    width: Constants.Grid.dp(30),
    height: Constants.Grid.dp(30),
    borderRadius: Constants.Grid.dp(15),
    marginRight: Constants.Grid.dp(10),
    alignItems: 'center',
    justifyContent: 'center',
  },
  titleAlone: {
    marginTop: Constants.Grid.dp(12),
    flex: 1,
  },
  titleWithDescription: {
    marginTop: Constants.Grid.dp(6),
    flex: 1,
  },
  description: {
    marginBottom: Constants.Grid.dp(18),
  },
  rightChevronIcon: {
    width: Constants.Grid.dp(24),
    height: Constants.Grid.dp(24),
  },
  switch: {
    marginTop: Constants.Grid.dp(6),
    transform: [
      {
        scale: 0.9,
      },
      {
        translateX: 5,
      },
    ],
  },
});
