import React from 'react';
import { Modal } from 'src/components/Modal';
import { Image, ViewStyle, StyleProp, StyleSheet, TouchableOpacity, View } from 'react-native';
import * as Constants from 'src/constants';
import * as Layout from 'src/components/Layout';
import Button from '../Button';
import HorizontalSeparator from '../HorizontalSeparator';
import Text from 'src/components/Text';
import VerticalSeparator from '../VerticalSeparator';

interface PropsIface {
  onAccept: () => void;
  onCancel?: () => void;
  visible: boolean;
  title: string;
  details?: string | null;
}

const AlertModal: React.FC<PropsIface> = (props) => {
  return (
    <Modal visible={props.visible} size='extra-thin' onClose={props.onCancel != null ? props.onCancel : props.onAccept}>
      <Layout.EdgeGutter style={[styles.content, Constants.GridStyle.MT4Unit, Constants.GridStyle.MB2Unit]}>
        <Text style={[Constants.TextStyle.T16B, Constants.TextStyle.ACenter, Constants.GridStyle.MBUnit]}>
          {props.title}
        </Text>
        <Layout.EdgeGutter style={styles.content}>
          {props.details != null ? (
            <Text
              style={[
                Constants.TextStyle.T12R,
                Constants.TextStyle.ACenter,
                Constants.GridStyle.MTUnit,
                Constants.GridStyle.MBUnit,
              ]}
            >
              {props.details}
            </Text>
          ) : null}
        </Layout.EdgeGutter>
      </Layout.EdgeGutter>
      <HorizontalSeparator />
      <View style={styles.buttonRow}>
        {props.onCancel != null ? (
          <>
            <TouchableOpacity onPress={props.onCancel} style={styles.button}>
              <Text style={[Constants.TextStyle.T12M, Constants.TextStyle.CBolt]}>{'Cancel'}</Text>
            </TouchableOpacity>
            <VerticalSeparator />
          </>
        ) : null}
        <TouchableOpacity onPress={props.onAccept} style={styles.button}>
          <Text style={[Constants.TextStyle.T12M, Constants.TextStyle.CBolt]}>{'OK'}</Text>
        </TouchableOpacity>
      </View>
    </Modal>
  );
};

export default AlertModal;

const styles = StyleSheet.create({
  closeIcon: {
    tintColor: Constants.NewColor.Black,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    flex: 1,
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    padding: Constants.Grid.dp(12),
  },
  buttonRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
});
