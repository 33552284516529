import moment from 'moment-timezone';

const MoneyCentGranularityNumberFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export type Formatter<T> = (v: T) => string;
export type NumberFormatter = Formatter<number>;
export type StringFormatter = Formatter<string>;
export type MomentFormatter = Formatter<moment.Moment>;

const MICRO: number = 1000000;
const CENTI: number = 100;

export const MoneyCentGranularity: NumberFormatter = (v) => MoneyCentGranularityNumberFormat.format(v);
export const MoneyCentGranularityCenti: NumberFormatter = (v) => MoneyCentGranularityNumberFormat.format(v / CENTI);
export const MoneyCentGranularityMicro: NumberFormatter = (v) => MoneyCentGranularityNumberFormat.format(v / MICRO);

const MoneyDollarGranularityNumberFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export const MoneyDollarGranularity: NumberFormatter = (v) => MoneyDollarGranularityNumberFormat.format(v);
export const MoneyDollarGranularityCenti: NumberFormatter = (v) => MoneyDollarGranularityNumberFormat.format(v / CENTI);
export const MoneyDollarGranularityMicro: NumberFormatter = (v) => MoneyDollarGranularityNumberFormat.format(v / MICRO);

const MoneyInputCentGranularityNumberFormat = new Intl.NumberFormat('en-US', {
  useGrouping: false,
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const MoneyInputCentGranularity: NumberFormatter = (v) => MoneyInputCentGranularityNumberFormat.format(v);

export const Percent: NumberFormatter = (v) => `${v}%`;

export const PercentFixedInt: NumberFormatter = (v) => `${Math.floor(v)}%`;

const SignedPercentFixedTwoDecimalsNumberFormat = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});
export const SignedPercentFixedTwoDecimals: NumberFormatter = (v) =>
  `${v > 0 ? '+' : ''}${SignedPercentFixedTwoDecimalsNumberFormat.format(v)}%`;

const IntWithCommasNumberFormat = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export const IntWithShorthand: NumberFormatter = (v) => {
  if (v >= 1000) {
    const clamped = Math.floor(v / 100) / 10;
    return `${clamped}k`;
  } else {
    return IntWithCommasNumberFormat.format(v);
  }
};
export const IntWithCommas: NumberFormatter = (v) => IntWithCommasNumberFormat.format(v);
export const IntWithCommasCenti: NumberFormatter = (v) => IntWithCommasNumberFormat.format(v / CENTI);
export const IntWithCommasMicro: NumberFormatter = (v) => IntWithCommasNumberFormat.format(v / MICRO);

export const DaysFromNow: MomentFormatter = (v) => {
  const daysLeft = Math.floor((v.valueOf() - Date.now()) / (86400 * 1000));
  if (daysLeft > 0) {
    return `in ${daysLeft} day${daysLeft === 1 ? '' : 's'}`;
  } else {
    return `${daysLeft} day${daysLeft === -1 ? '' : 's'} ago`;
  }
};
export const FromNow: MomentFormatter = (v) => v.fromNow();
export const FromNowWithoutSuffix: MomentFormatter = (v) => v.fromNow(true);

// Modified https://stackoverflow.com/a/3177838
export const ShortPastFromNow: MomentFormatter = (v) => {
  var seconds = Math.floor((Date.now() - v.valueOf()) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return DateYearLabel(v);
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return DateLabel(v);
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + 'd';
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + 'h';
  }
  interval = seconds / 60;
  return Math.max(Math.floor(interval), 1) + 'm';
};

// Modified https://stackoverflow.com/a/3177838
export const ProgressPastDateFromNow: MomentFormatter = (v) => {
  var seconds = Math.floor((Date.now() - v.valueOf()) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return DateYearLabel(v);
  }
  return DateLabel(v);
};

export const ShortFutureFromNow: MomentFormatter = (v) => {
  // Seconds difference
  var seconds = Math.floor((v.valueOf() - Date.now()) / 1000);
  var interval = seconds / 31536000;
  if (interval > 1) {
    return DateYearLabel(v);
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return DateLabel(v);
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + ' day' + (Math.floor(interval) === 1 ? '' : 's');
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + ' hour' + (Math.floor(interval) === 1 ? '' : 's');
  }
  interval = seconds / 60;
  return Math.max(Math.floor(interval), 1) + ' minute' + (Math.floor(interval) === 1 ? '' : 's');
};

export const ProgressiveDate: MomentFormatter = (v) => {
  const m = moment();
  const daysDiff = m.clone().diff(v, 'days');
  if (daysDiff < 1) {
    return 'Today';
  } else if (daysDiff < 2) {
    return 'Yesterday';
  } else if (daysDiff < 7) {
    return v.format('dddd');
  } else if (v.get('year') === m.clone().get('year')) {
    return v.format('ddd, MMM D');
  } else {
    return v.format('ddd, MMM D, YYYY');
  }
};

export const DateWithDoWLabel: MomentFormatter = (v) => v.format('ddd, MMM D');
export const DayDateYearLabel: MomentFormatter = (v) => v.format('dddd MMM DD, YYYY');
export const DateYearLabel: MomentFormatter = (v) => v.format('MMM DD, YYYY');
export const DateLabel: MomentFormatter = (v) => v.format('MMM DD');
export const DateTime: MomentFormatter = (v) => v.format('MMM DD h:mm A');
export const Time: MomentFormatter = (v) => v.format('h:mm A');

// Note (albert): This suffers from the same delimiter issue as web :)
export const E164National: StringFormatter = (v) => {
  const digits = v
    .replace(/\+1/g, '') // TODO (albert) This is approximate, only works for US and Canada
    .replace(/\D+/g, '');
  if (digits.length === 0) {
    return '';
  } else if (digits.length < 4) {
    return `(${digits.slice(0, 3)}`;
  } else if (digits.length < 7) {
    return `(${digits.slice(0, 3)}) ${digits.slice(3, 6)}`;
  } else {
    return `(${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(6, 10)}`;
  }
};
