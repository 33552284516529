import * as React from 'react';
import * as Constants from 'src/constants';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

interface PropsIface {
  style?: StyleProp<ViewStyle>;
}

const VerticalSeparator: React.FC<PropsIface> = (props) => <View style={[styles.root, props.style]} />;

const styles = StyleSheet.create({
  root: {
    width: StyleSheet.hairlineWidth,
    height: '100%',
    backgroundColor: Constants.NewColor.OnWhiteBorderGray,
  },
});

export default VerticalSeparator;
