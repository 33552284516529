import * as React from 'react';
import * as Util from 'src/util';
import moment from 'moment-timezone';
import { WithMemo } from 'src/components/Format/WithMemo';

interface PropsIface {
  formatter: Util.Format.Formatter<moment.Moment>;
  at: number;
  tz?: string;
  renderInterval?: number;
}

function _WithMomentMemo(props: PropsIface): JSX.Element {
  const value: moment.Moment = React.useMemo(() => {
    return props.tz != null ? moment(props.at).tz(props.tz) : moment(props.at);
  }, [props.at, props.tz]);
  return <WithMemo<moment.Moment> value={value} formatter={props.formatter} renderInterval={props.renderInterval} />;
}

export const WithMomentMemo = React.memo(_WithMomentMemo) as typeof _WithMomentMemo;
