import * as React from 'react';
import * as Constants from 'src/constants';
import { EdgeGutter } from '../EdgeGutter';
import * as Format from 'src/components/Format';
import * as Util from 'src/util';
import Text from 'src/components/Text';
import { Animated, Insets, StyleProp, StyleSheet, TextStyle, TouchableOpacity, View } from 'react-native';

interface PropsIface {
  titleNode?: React.ReactNode;
  highlightNode: React.ReactNode;
  descriptionNode?: React.ReactNode;
  highlightNodeTextStyle?: StyleProp<TextStyle>;
  updatedAt?: number;
  onRefresh?: () => Promise<any>;
}

const REFRESH_HIT_SLOP: Insets = {
  top: 20,
  left: 20,
  right: 20,
  bottom: 20,
};

const refreshIconSource = {
  uri: '/static/images/app/RefreshIcon@3x.png',
};

export const StatSummary: React.FC<PropsIface> = (props) => {
  const [refreshing, setRefreshing] = React.useState<boolean>(false);
  const [refreshSpinValue] = React.useState<Animated.Value>(new Animated.Value(0));

  const onRefresh = React.useCallback(() => {
    if (props.onRefresh != null) {
      setRefreshing(true);
      props.onRefresh().finally(() => {
        setRefreshing(false);
      });
    }
  }, [props.onRefresh]);

  React.useEffect(() => {
    if (!refreshing) {
      Animated.timing(refreshSpinValue, {
        toValue: 0,
        duration: 250,
        useNativeDriver: true,
      }).start();
    } else {
      Animated.timing(refreshSpinValue, {
        toValue: 1,
        duration: 1500,
        useNativeDriver: true,
      }).start();
    }
  }, [refreshing]);

  const dynamicSpinStyle: {} = React.useMemo(() => {
    return {
      transform: [
        {
          rotate: refreshSpinValue.interpolate({
            inputRange: [0, 1],
            outputRange: ['0deg', '360deg'],
          }),
        },
        {
          scale: refreshSpinValue.interpolate({
            inputRange: [0, 1],
            outputRange: [1, 0.8],
          }),
        },
      ],
    };
  }, []);

  return (
    <EdgeGutter style={styles.root}>
      {props.titleNode != null ? (
        <View style={styles.topRow}>
          <Text style={[Constants.TextStyle.T12R, Constants.TextStyle.CSecondary]}>{props.titleNode}</Text>
          {props.updatedAt != null ? (
            <Text style={[Constants.TextStyle.T12R, Constants.TextStyle.CSecondary]}>
              <Format.WithMomentMemo at={props.updatedAt} formatter={Util.Format.FromNow} renderInterval={5000} />
            </Text>
          ) : null}
        </View>
      ) : null}
      <View style={styles.highlightRow}>
        <Text style={[Constants.TextStyle.T24B, props.highlightNodeTextStyle]}>{props.highlightNode}</Text>
        {props.onRefresh != null ? (
          <TouchableOpacity
            disabled={refreshing}
            onPress={onRefresh}
            style={refreshing ? styles.refreshingButton : null}
            hitSlop={REFRESH_HIT_SLOP}
          >
            <Animated.Image source={refreshIconSource} style={[dynamicSpinStyle, styles.refreshIcon]} />
          </TouchableOpacity>
        ) : null}
      </View>
      {props.descriptionNode != null ? (
        <View style={styles.infoRow}>
          <Text style={[Constants.TextStyle.T12R, Constants.TextStyle.CSecondary]}>{props.descriptionNode}</Text>
        </View>
      ) : null}
    </EdgeGutter>
  );
};

const styles = StyleSheet.create({
  root: {
    paddingTop: Constants.Grid.dp(16),
    paddingBottom: Constants.Grid.dp(8),
  },
  topRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  refreshingButton: {
    opacity: 0.2,
  },
  highlightRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  refreshIcon: {
    width: Constants.Grid.dp(24),
    height: Constants.Grid.dp(24),
  },
  infoRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});
