import * as React from 'react';
import * as Constants from 'src/constants';
import * as Network from 'src/clients/Network';
import * as State from 'src/state';
import AlertModal from 'src/components/AlertModal';
import { SettingsSwitchRow } from '../SettingsSwitchRow';

export const toggleFeatureStatus = async (f: () => Promise<boolean>): Promise<void> => {
  let success: boolean = false;
  try {
    const res = await f();
    success = res;
    if (success) {
      await Network.gql.__deprecated__getPoshmarkUserLinks({});
    }
  } catch (e) {
    console.error(e);
  }
};

export type OnBuildToggleArgsType = (
  value: boolean
) => Omit<Network.gql.build.toggleFeatureStatusesMutation.toggleFeatureStatusesMutationVariables, 'poshmarkUserLinkId'>;

interface PropsIface {
  trackColor?: Constants.Color;
  titleNode: React.ReactNode;
  descriptionNode?: React.ReactNode;
  argsFeatureStatus: keyof Omit<
    Network.gql.build.toggleFeatureStatusesMutation.toggleFeatureStatusesMutationVariables,
    'poshmarkUserLinkId'
  >;
  readFeatureStatus: Exclude<State.Types.PoshmarkUserLinkKeyType, 'poshmarkUserLinkId'>;
  inverted?: boolean;
  onPress?: () => void;
  disabled?: boolean;
}

const SettingsFeatureSwitchRowWithoutData: React.FC<PropsIface> = (props) => {
  const userLink = React.useContext(State.Observe.PoshmarkUserLinks.SelectedFallback.Get);
  const [successAlertVisible, setSuccessAlertVisible] = React.useState<boolean>(false);
  const [failedAlertVisible, setFailedAlertVisible] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  const currentValue: boolean = React.useMemo(() => {
    const isEnabled = userLink[props.readFeatureStatus] === 'ENABLED';
    return props.inverted ? !isEnabled : isEnabled;
  }, [userLink, props.readFeatureStatus, props.inverted]);

  const onChange = React.useCallback(
    (on: boolean) => {
      (async () => {
        if (!props.disabled) {
          setLoading(true);
          await toggleFeatureStatus(() =>
            Network.gql
              .toggleFeatureStatuses({
                poshmarkUserLinkId: userLink.id,
                [props.argsFeatureStatus]: props.inverted ? !on : on,
              })
              .then((_) => {
                const res = _.superposherToggleFeatureStatuses.success;
                if (res) {
                  setSuccessAlertVisible(true);
                } else {
                  setFailedAlertVisible(true);
                }
                return res;
              })
          );
          setLoading(false);
        }
      })();
    },
    [userLink.poshmarkUserId, props.argsFeatureStatus, props.inverted, props.disabled]
  );

  const onSuccessAlertAccept = React.useCallback(() => {
    setSuccessAlertVisible(false);
  }, [setSuccessAlertVisible]);

  const onFailedAlertAccept = React.useCallback(() => {
    setFailedAlertVisible(false);
  }, [setFailedAlertVisible]);

  return (
    <>
      <SettingsSwitchRow
        {...props}
        defaultValue={currentValue}
        disabled={loading || props.disabled}
        onChange={onChange}
      />
      <AlertModal
        onAccept={onSuccessAlertAccept}
        visible={successAlertVisible}
        title='Saved changes'
        details='Your change should start taking effect shortly!'
      />
      <AlertModal
        onAccept={onFailedAlertAccept}
        visible={failedAlertVisible}
        title='Unexpected error'
        details='Failed to save your change. Try again later.'
      />
    </>
  );
};

export const SettingsFeatureSwitchRow: React.FC<PropsIface> = (props) => {
  return (
    <State.Observe.PoshmarkUserLinks.SelectedFallback.Provider>
      <SettingsFeatureSwitchRowWithoutData {...props} />
    </State.Observe.PoshmarkUserLinks.SelectedFallback.Provider>
  );
};
