import * as React from 'react';
import * as Constants from 'src/constants';
import { StyleSheet, View, ViewProps } from 'react-native';

/**
 * For the horizontal gutters on the edge of the screen.
 */
export const CardGutter: React.FC<ViewProps> = (props) => {
  return <View {...props} style={[styles.root, props.style]} />;
};

const styles = StyleSheet.create({
  root: {
    paddingHorizontal: 2 * Constants.Grid.Unit,
  },
});
