import * as React from 'react';
import * as Util from 'src/util';

interface PropsIface<T> {
  formatter: Util.Format.Formatter<T>;
  value: T;
  renderInterval?: number;
}

function _WithMemo<T>(props: PropsIface<T>): JSX.Element {
  const [label, setLabel] = React.useState<string>(props.formatter != null ? props.formatter(props.value) : '');

  React.useEffect(() => {
    let intervalId: undefined | ReturnType<typeof setInterval>;
    if (props.renderInterval != null) {
      intervalId = setInterval(() => {
        // Note (albert): Unsure why, but formatter has the possibility of being undefined sometimes...
        if (props.formatter != null) {
          setLabel(props.formatter(props.value));
        }
      }, props.renderInterval);
    }
    if (props.formatter != null) {
      setLabel(props.formatter(props.value));
    }
    return () => {
      if (intervalId != null) {
        clearInterval(intervalId);
      }
    };
  }, [props.formatter, props.value, props.renderInterval]);

  return <>{label}</>;
}

export const WithMemo = React.memo(_WithMemo) as typeof _WithMemo;
