import * as React from 'react';
import * as Constants from 'src/constants';
import { EdgeGutter } from '../EdgeGutter';
import Text from 'src/components/Text';
import { Image, StyleSheet, StyleProp, View, ViewStyle } from 'react-native';

interface PropsIface {
  iconNode?: React.ReactNode;
  iconBackgroundColor?: string;
  titleNode: React.ReactNode;
  descriptionNode?: React.ReactNode;
  valueNode?: React.ReactNode;
  onPress?: () => void;
  style?: StyleProp<ViewStyle>;
}

const rightChevronIconSource = {
  uri: '/static/images/app/RightChevronIcon.png',
};

export const SummaryRow: React.FC<PropsIface> = (props) => {
  const iconBackgroundStyle: ViewStyle = React.useMemo(() => {
    return {
      backgroundColor: props.iconBackgroundColor ?? Constants.NewColor.Black,
    };
  }, [props.iconBackgroundColor]);

  return (
    <EdgeGutter style={[styles.root, props.style]} onPress={props.onPress}>
      {props.iconNode != null ? <View style={[iconBackgroundStyle, styles.icon]}>{props.iconNode}</View> : null}
      <View style={styles.title}>
        <Text style={[Constants.TextStyle.T16B]}>{props.titleNode}</Text>
        {props.descriptionNode != null ? (
          <Text style={[styles.description, Constants.TextStyle.T12R, Constants.TextStyle.CSecondary]}>
            {props.descriptionNode}
          </Text>
        ) : null}
      </View>
      <View>
        <Text style={[Constants.TextStyle.T16B, Constants.TextStyle.ARight]}>{props.valueNode}</Text>
      </View>
      {props.onPress != null ? (
        <View style={styles.iconContainer}>
          <Image source={rightChevronIconSource} style={styles.rightChevronIcon} />
        </View>
      ) : null}
    </EdgeGutter>
  );
};

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    height: Constants.Grid.dp(48),
    alignItems: 'center',
  },
  description: {
    position: 'absolute',
    left: 0,
    top: Constants.Grid.dp(18),
  },
  iconContainer: {
    transform: [
      {
        translateX: Constants.Grid.dp(7),
      },
    ],
  },
  icon: {
    width: Constants.Grid.dp(30),
    height: Constants.Grid.dp(30),
    borderRadius: Constants.Grid.dp(15),
    marginRight: Constants.Grid.dp(10),
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    flex: 1,
  },
  rightChevronIcon: {
    width: Constants.Grid.dp(24),
    height: Constants.Grid.dp(24),
  },
});
